import React from 'react';

const Loader = () => (
  <div className="loader-container">
    <div className="loader">
      <img className="loaderlogo" src="/assets/images/remlogo.png" alt="REM Logo" />
    </div>
  </div>
);

export default Loader;
