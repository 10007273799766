import React from 'react';


const ComoFunciona = () => {
    return (
    <div className="mb-5">
          <h1 className="">Cómo Funciona</h1>


          <h3>Imagenes con estilos unicos</h3>
          <div id='pasosuser'>
            <div className='paso'>
              <h5>
                <strong>Paso 1</strong> 
              </h5>
              <p className="text">
                Explora las Opciones. Selecciona el estilo de imagen que deseas crear.
              </p>
            </div>

            <div className='paso'>
              <h5>
                <strong>Paso 2.1</strong> 
              </h5>
              <p className="text">
                Escribe Tu Descripción. Ahora, escribe lo que te gustaría ver. Sé detallado: colores, estilos, objetos, escenas. Cuanto más específico seas, mejor será el resultado.
              </p>
            </div> 

            <div className='paso'>
              <h5>
                <strong>Paso 2.2</strong> 
              </h5>
              <p className="text">
                Contamos con un generador te Prompt por AI, con este puedes generar el texto al azar con AI, lo cual te permitira generar un excelente resultado.
              </p>
            </div>

            <div className='paso'>
              <h5>
                <strong>Paso 3</strong> 
              </h5>
              <p className="text">
                Genera y Descubre. Haz clic en "Generar Imagen" y observa cómo tus palabras cobran vida. Si te gusta el resultado, puedes proceder a crear producto y luego comparlo en nuestra tienda <strong>WWW.REMENTERPRISES.UY.</strong>
              </p>
            </div>  
          </div>



          <h3>Imagenes con tu rostro</h3>
          <div id='pasosuser'>
            <div className='paso'>
              <h5>
                <strong>Paso 1</strong> 
              </h5>
              <p className="text">
                <strong>Subí tus fotos:</strong> Cargá hasta 6 imágenes de diferentes perfiles.
              </p>
            </div>

            <div className='paso'>
              <h5>
                <strong>Paso 2</strong> 
              </h5>
              <p className="text">
                <strong>Escribí tu pedido:</strong> Explicá cómo querés modificar la imagen.
              </p>
            </div> 

            <div className='paso'>
              <h5>
                <strong>Paso 3</strong> 
              </h5>
              <p className="text">
                <strong>Generá la imagen:</strong> La IA procesará las fotos y tu descripción.
              </p>
            </div>  

            <div className='paso'>
              <h5>
                <strong>Paso 4</strong> 
              </h5>
              <p className="text">
                <strong>Visualizá y comprá:</strong> Revisá el mockup del producto y compralo directamente en la tienda.
              </p>
            </div> 

          </div>
          
          <p className="text text-success">
            Consejo: No tengas miedo de experimentar. La creatividad no tiene límites aquí, y cada nueva combinación de palabras puede dar vida a algo extraordinario.
          </p>
          
        </div>


    );
};

export default ComoFunciona;
