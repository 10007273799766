import React from 'react';
import Login from './Login.js';
import { useAuth } from './hooks/useAuth';

const LogInContainer = () => {
  const { setLoggedInUser } = useAuth();

  const handleLogin = (user) => {
    setLoggedInUser(user); 
  };

  return (
    <>
        <Login onLogin={handleLogin} /> 
    </>
  );
};

export default LogInContainer;
