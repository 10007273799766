import React from 'react';

const Footer = () => {
  return (
    <footer className="text-center mt-5 py-3">
      <p className="mb-0">
        Desarrollado por <a href="https://moreirawebdesign.com" target="_blank" rel="noopener noreferrer">Moreira Dev</a>
      </p>
    </footer>
  );
};

export default Footer;
